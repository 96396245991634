<template>
  <div class="d-flex flex-row h-100">
    <div class="d-flex align-items-end flex-column">
      <div>
        <e-button v-model="selectedMode" value-key="map" text="MAP" />
      </div>
      <div>
        <e-button v-model="selectedMode" value-key="cams" text="CAMS" />
      </div>
      <!--<div v-if="lidarConnected">-->
      <div>
        <e-button v-model="selectedMode" value-key="lidar" text="LIDAR" />
      </div>
      <div>
        <e-button v-model="selectedMode" value-key="gpr" text="GPR" />
      </div>
      <div class="mt-auto">
        <e-button text="Legenda" icon="legenda" />
      </div>
    </div>

    <keep-alive>
      <maps-child-view v-if="selectedMode === 'map'" ref="mapchild" />
    </keep-alive>
    <cams-child-view v-if="selectedMode === 'cams'" />
    <keep-alive>
      <lidar-child-view v-if="selectedMode === 'lidar'" />
    </keep-alive>
    <keep-alive>
    <g-p-r-child-view v-if="selectedMode === 'gpr'" />
    </keep-alive>
  </div>
</template>

<script>
/* eslint-disable vue/no-side-effects-in-computed-properties */
import CamsChildView from "@/views/map/CamsChildView";
import MapsChildView from "@/views/map/MapChildView";
import EButton from "@/components/Button";
import LidarChildView from "@/views/map/LidarChildView";
import GPRChildView from "@/views/map/GPRChildView";

export default {
  name: "QuickSwitcher",
  emits: ["changed"],
  components: {
    GPRChildView,
    LidarChildView,
    EButton,
    MapsChildView,
    CamsChildView,
  },
  data: function () {
    return {
      selectedMode: "map",
      lidar: null,
    };
  },
  computed: {
    lidarConnected() {
      const workers = this.$store.state.workers;
      let lidarWorker = null;
      // eslint-disable-next-line no-unused-vars
      workers.forEach((worker) => {
        if (worker.subtype && worker.subtype.toUpperCase() === "LIDAR") {
          lidarWorker = worker;
        }
      });
      this.lidar = lidarWorker;
      if (this.lidar == null) {
        this.selectedMode = "map";
      }
      return lidarWorker != null;
    },
  },
  watch: {
    // Whenever the mode changes to something else than the map, stop the interaction
    selectedMode: function (newMode, oldMode) {
      if (oldMode == "map") {
        this.$emit("changed", oldMode);
      }
    },
  },
  methods: {
    forceView: function (view) {
      this.selectedMode = view;
    },
    getView: function () {
      return this.selectedMode;
    },
    stopMapInteraction: function () {
      this.$refs.mapchild.stopMapInteraction();
    },
    setMapTooltip: function (text) {
      this.$refs.mapchild.setMapTooltip(text);
    },
    startMapInteraction: function (callback, type, svg = undefined) {
      this.$refs.mapchild.startMapInteraction(callback, type, svg);
    },
  },
};
</script>

<style scoped>
</style>