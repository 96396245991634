
<template>
  <div class="navbar theme-dark">
    <div class="container no-padding text-center nav-vertical">
      <img id="logo" src="@/assets/logo.png" alt="Logo" />
      <ul>
        <li>
          <router-link active-class="active" :to="{ name: 'Home' }">
            <e-icon class="icon" icon="map" nav/>
          </router-link>
        </li>
        <li>
          <router-link active-class="active" :to="{ name: 'Cams' }">
            <e-icon class="icon" icon="camera" nav/>
          </router-link>
        </li>
        <li>
          <router-link active-class="active" :to="{ name: 'ProjectInfo' }">
            <e-icon class="icon" icon="project_info" nav/>
          </router-link>
        </li>
        <li>
          <router-link active-class="active" :to="{ name: 'Dataflow' }">
            <e-icon class="icon" icon="data_flow" nav/>
          </router-link>
        </li>
        <li>
          <router-link active-class="active" :to="{ name: 'Browser' }">
            <e-icon class="icon" icon="browser" nav/>
          </router-link>
        </li>
      </ul>
    </div>
    <div class="version">Build {{ version }}</div>
    <div class="connection">
      <span
        class="status"
        v-bind:class="{ online: socketConnnected, offline: !socketConnnected }"
      ></span>
      <span v-if="socketConnnected">Connected</span>
      <span v-else>Disconnected</span>
    </div>
  </div>
</template>

<script>
import EIcon from "@/components/Icon";
const appVersion = require("../../package.json").version;
/* eslint-disable vue/no-unused-components */

export default {
  name: "NavBar",
  props: {
    msg: String,
  },
  components: { EIcon },
  data() {
    return {
      version: appVersion,
    };
  },
  computed: {
    socketConnnected() {
      return this.$store.state.socketConnection;
    },
  },
};
</script>

<style lang="scss">
.nav-vertical {
  margin-left: 25px !important;
}

.version {
  position: absolute;
  width: 150px;
  bottom: 40px;
  text-align: center;
  font-size: 80%;
}

.connection {
  position: absolute;
  width: 150px;
  bottom: 15px;
  text-align: center;
  font-size: 80%;
}

.connection .status {
  height: 10px;
  width: 10px;
  margin-right: 5px;
  border-radius: 50%;
  display: inline-block;
}

.connection .online {
  background-color: lightgreen;
}

.connection .offline {
  background-color: red;
}

.navbar {
  background-color: var(--color-primary);
  color: var(--font-color);
  height: 100vh;
  display: block;
  width: 150px;
  top: 0;
  left: 0;
  margin-left: 15px;

  #logo {
    margin: 0 auto;
    margin-top: 15px;
    margin-right: 30%;
  }

  ul {
    list-style: none;
    padding: 0;
    text-align: center;
    width: 100%;

    li {
      margin-bottom: -70px;
      text-align: center;

      a {
        color: var(--font-color);
        text-decoration: none;
        font-size: 50px;
        display: block;
        width: 150px;
        margin-left: -15px;
        height: 210px;
        line-height: 80px;

        &.active {
          // background-color: var(--color-accent);
          background: url('/icons/menu_button.svg');
          background-size: cover;
          background-position: center;

          .icon {
            filter: brightness(0) invert(1);
          }
        }
      }
    }
  }
}
</style>
