<template>
  <div class="container-fluid h-100">
    <img src="@/assets/img/gprPreview.jpg"/>
  </div>
</template>

<script>
export default {
  name: "GPRChildView"
}
</script>

<style scoped>
img {
  width: 100%
}
</style>