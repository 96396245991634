<template>
  <div class="chart-wrapper h-100" ref="chart-wrapper">
    <apexchart
        ref="chart"
        type="bar"
        height="100%"
        :options="chartOptions"
        :series="series"
    ></apexchart>
  </div>
</template>

<script>

import VueApexCharts from 'vue3-apexcharts'

export default {
  components: {
    apexchart: VueApexCharts
  },
  props: {
    wrapperElement: Object
  },
  mounted() {
    this.$nextTick(function () {
      window.addEventListener('resize', this.resize);
    })
  },
  data: function () {
    return {
      chartOptions: {
        chart: {
          id: "vuechart-example",
          sparkline: {
            enabled: false
          },
          foreColor: '#FFFFFF',
          redrawOnParentResize: true
        },
        plotOptions: {
          bar: {
            columnWidth: '40%'
          }
        },
        colors: ['#d2a52b'],
        xaxis: {
          categories: ['Cam1', 'Cam2', 'Cam3', 'GPS', 'EVENTS', 'LADYBUG', 'LIDAR', 'GPS'],
        },
      },
      series: [
        {
          name: "series-1",
          data: [10, 8, 9, 6, 8, 10, 9, 7],
        },
      ],
    };
  },
  onBeforeUnmount() {
    window.removeEventListener('resize', this.resize);
  },
  methods: {
    resize() {
      window.ApexCharts.exec('vuechart-example', 'updateOptions', {
        // chart: {
        //   height: this.wrapperElement.clientHeight
        // }
      });
    },
    updateChart() {

    }
  },
  watch: {
    'wrapperElement.clientHeight': function (newVal, oldVal) {
      console.log(newVal, oldVal);
    }
  }
};
</script>

<style scoped lang="scss">
.chart-wrapper {
  height: 100%;
}
</style>