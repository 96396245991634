import { createApp } from 'vue'
import App from './App.vue'
import VueSocketIO from 'vue-socket.io'
import SocketIO from 'socket.io-client'
import router from "@/routing/router";
import store from './store';
import './assets/css/bootstrap-grid.min.css'
import './assets/css/main.scss';

// Vue.config.productionTip = false

// Config Store
let ipAddress = 'http://mms.emit-it.com:3000';
if (navigator.userAgent.toLowerCase().indexOf(' electron/') != -1) {
    const fs = require('fs');
    if (fs.existsSync('user.config')) {
        console.log('User config exists!');
        const data = fs.readFileSync('user.config', 'utf-8');
        if (data) {
            const ip = data.split('=');
            if (ip[0] === 'HUB_IP') {
                console.log(data);
                ipAddress = ip[1];
            }
        }
    } else {
        fs.writeFileSync('user.config', 'HUB_IP=http://127.0.0.1:3000');
    }
}

createApp(App)
    .use(new VueSocketIO({
        debug: false,
        connection: SocketIO(ipAddress),
        vuex: {
            store,
            mutationPrefix: "SOCKET_",
        }
    }))
    .use(router)
    .use(store)
    .mount('#app');