<template>
  <div :id="mapname" style="width: 100%; height: 100%;">
    <div v-if="tooltip" class="map-info-text">{{ tooltip }}</div>
  </div>
</template>

<script>
import MapManager from "@/assets/js/map-manager";

export default {
  name: "e-map",
  props: ['mapname'],
  data: function () {
    return {
      map: null,
      tooltip: null,
    };
  },
  watch: {
    "$store.state.importedShapes": {
      handler: function (nv) {
        if (nv != null) {
          console.log("UPDATED GEO");
          if (this.map && nv) {
            this.map.addGeoJson(nv);
          }
        }
      },
      immediate: true,
      deep: true,
    },
    "$store.state.importedShapesColor": {
      handler: function (nv) {
        if (nv != null) {
          console.log("NEW COLOR");
          if (this.map && nv) {
            this.map.changeLayerColor(nv);
          }
        }
      },
      immediate: true,
      deep: true,
    },
    "$store.state.importedShapesMinZoom": {
      handler: function (nv) {
        console.log("NEW ZOOM");
        if (this.map && nv) {
          this.map.changeLayerZoom(nv, true);
        }
      },
      immediate: true,
      deep: true,
    },
    "$store.state.importedShapesMaxZoom": {
      handler: function (nv) {
        console.log("NEW ZOOM");
        if (this.map && nv) {
          this.map.changeLayerZoom(nv, false);
        }
      },
      immediate: true,
      deep: true,
    },
  },
  mounted() {
    this.map = new MapManager(this.mapname);
    this.map.addGeoJson(this.$store.state.importedShapes);
    this.map.changeLayerColor(this.$store.state.importedShapesColor);
    this.map.changeLayerZoom(this.$store.state.importedShapesMinZoom, true);
    this.map.changeLayerZoom(this.$store.state.importedShapesMaxZoom, false);
  },
  methods: {
    changeLayerColor: function (layer) {
      this.map.changeLayerColor(layer);
    },
    startInteraction(callback, type, svg = undefined) {
      this.map.startInteraction(callback, type, svg);
    },
    stopInteraction() {
      this.map.stopInteraction();
    },
    setTooltip(text) {
      this.tooltip = text;
    }
  },
};
</script>

<style lang="scss" scoped>
#map {
  height: 100%;
}

.map-info-text {
  position: absolute;
  width: 100%;
  height: 40px;
  line-height: 40px;
  color: var(--color-accent);
  top: 0;
  text-transform: uppercase;
  text-align: center;
  background: rgba(0, 0, 0, 0.6);
  z-index: 999;
}
</style>