<template>
  <div class="flex-grow-1"><point-cloud-viewer :points="pointCloud"/></div>
</template>

<script>
import PointCloudViewer from "@/components/PointCloudViewer";

export default {
  name: "LidarChildView",
  components: { PointCloudViewer },
  data() {
    return {
      canvasContainer: null,
      canvas: null,
      lidarWorker: null,
      pointCloud: null,
    };
  },
  mounted() {
    this.lidarWorker = this.$store.state.workers.find(
      (worker) => worker.subtype && worker.subtype.toUpperCase() == "LIDAR"
    );
    // this.feedFrame(lidarData);
  },
  watch: {
    "$store.state.workers": {
      handler: function (nv) {
        if (nv != null) {
          this.lidarWorker = nv.find(
            (worker) =>
              worker.subtype && worker.subtype.toUpperCase() == "LIDAR"
          );
        }
      },
      immediate: true,
      deep: true,
    },
  },
  sockets: {
    hub_push_gui_preview: function (data) {
      if (this.lidarWorker?.id == data.id) {
        this.pointCloud = data.buffer;
        console.log("Pushed points");
      }
    },
  },
};
</script>

<style scoped>
img {
  width: 100%;
}
</style>