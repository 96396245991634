<template>
  <div class="chart-wrapper h-100" ref="chart-wrapper">
    <apexchart ref="chart" type="line" :options="chartOptions"
               :series="series"></apexchart>
  </div>
</template>

<script>

import VueApexCharts from 'vue3-apexcharts'

export default {
  name: 'LineChart',
  components: {
    apexchart: VueApexCharts
  },
  props: {
    wrapperElement: Object
  },
  mounted() {
    this.$nextTick(function () {
      window.addEventListener('resize', this.resize);
    })
  },
  data: function () {
    return {
      series: [{
        name: "Session Duration",
        data: [45, 52, 38, 24, 33, 26, 21, 20, 6, 8, 15, 10]
      },
        {
          name: "Page Views",
          data: [35, 41, 62, 42, 13, 18, 29, 37, 36, 51, 32, 35]
        },
        {
          name: 'Total Visits',
          data: [87, 57, 74, 99, 75, 38, 62, 47, 82, 56, 45, 47]
        }
      ],
      chartOptions: {
        chart: {
          width: '100%',
          type: 'line',
          foreColor: 'white',
          zoom: {
            enabled: false
          },
          redrawOnParentResize: true
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          width: [5, 7, 5],
          curve: 'straight',
          dashArray: [0, 8, 5]
        },
        title: {
          text: 'Page Statistics',
          align: 'left'
        },
        legend: {
          tooltipHoverFormatter: function (val, opts) {
            return val + ' - ' + opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] + ''
          }
        },
        markers: {
          size: 0,
          hover: {
            sizeOffset: 6
          }
        },
        xaxis: {
          categories: ['01 Jan', '02 Jan', '03 Jan', '04 Jan', '05 Jan', '06 Jan', '07 Jan', '08 Jan', '09 Jan',
            '10 Jan', '11 Jan', '12 Jan'
          ],
        },
        tooltip: {
          y: [
            {
              title: {
                formatter: function (val) {
                  return val + " (mins)"
                }
              }
            },
            {
              title: {
                formatter: function (val) {
                  return val + " per session"
                }
              }
            },
            {
              title: {
                formatter: function (val) {
                  return val;
                }
              }
            }
          ]
        },
        grid: {
          borderColor: '#f1f1f1',
        }
      }
    };
  },
  onBeforeUnmount() {
    window.removeEventListener('resize', this.resize);
  },
  methods: {
    resize() {
      window.ApexCharts.exec('chart', 'updateOptions', {
        // chart: {
        //   height: this.wrapperElement.clientHeight
        // }
      });
    },
    updateChart() {

    }
  },
  watch: {
    'wrapperElement.clientHeight': function (newVal, oldVal) {
      console.log(newVal, oldVal);
    }
  }
};
</script>

<style scoped lang="scss">
.chart-wrapper {
  height: 100%;
}
</style>