<template>
  <div class="container-fluid h-100">
    <div class="row h-100">
      <div class="col h-100 no-padding">
        <div class="d-flex flex-column h-100">
          <div class="h-100">
            <div class="wrapper">
              <div v-if="configurableWorkers.length > 0" class="tabs">
                <div
                  v-for="(worker, idx) in configurableWorkers"
                  :key="worker"
                  class="tab"
                >
                  <input
                    type="radio"
                    name="css-tabs"
                    :id="'tab-' + idx"
                    :checked="idx == 0"
                    class="tab-switch"
                  />
                  <label :for="'tab-' + idx" class="tab-label">{{
                    worker.display_name
                  }}</label>
                  <div class="tab-content">
                    <div class="container-fluid h-100">
                      <div class="row h-100">
                        <div class="col-12">
                          <div class="d-flex h-100 flex-column">
                            <div class="h-25">
                              <div class="row" v-if="worker.telemetry && componentActive">
                                <div
                                  v-if="
                                    worker.telemetry.system_status &&
                                    worker.telemetry.message
                                  "
                                  v-bind:class="{
                                    'alert-success':
                                      worker.telemetry.system_status == '0',
                                    'alert-warning':
                                      worker.telemetry.system_status == '1',
                                    'alert-danger':
                                      worker.telemetry.system_status != '0' &&
                                      worker.telemetry.system_status != '1',
                                  }"
                                  class="alert text-center"
                                  role="alert"
                                  style="margin-top: 5px"
                                >
                                  {{ worker.telemetry.message }}
                                </div>

                                <h2
                                  class="title text-white"
                                  v-if="
                                    worker.telemetry.telemetry &&
                                    worker.telemetry.telemetry.length > 0
                                  "
                                >
                                  Live Telemetry
                                </h2>
                                <h4 v-else class="text-center text-white">
                                  The worker has not supplied any statistical
                                  data at this time
                                </h4>
                                <div
                                  class="col-sm-12 col-md-2 background-tint"
                                  v-for="chart in worker.telemetry.telemetry"
                                  :key="chart"
                                >
                                  <vue-apex-charts
                                    type="radialBar"
                                    :options="createChart(chart.name)"
                                    :series="[Math.round(chart.value * 10) / 10]"
                                  ></vue-apex-charts>
                                </div>
                              </div>
                              <div v-else>
                                <h4 class="text-center text-white">
                                  Awaiting telemetry data from worker "{{
                                    worker.display_name
                                  }}"...
                                </h4>
                              </div>
                            </div>
                            <!-- <div class="h-40">
                              <h2 class="title text-white">DROPPED FRAMES</h2>
                              <div class="flex-grow-1">
                                <line-chart />
                              </div>
                            </div> -->
                          </div>
                        </div>

                        <!-- <div class="col-12 h-100" style="margin-top: 25px">
                          <div class="row h-60" ref="chart-wrapper1">
                            <h2 class="title text-white">Historic</h2>
                            <div class="col-md-12 h-100">
                              <vue-apex-charts
                                type="area"
                                height="350"
                                :options="chartOptions"
                                :series="series"
                              />
                            </div>
                          </div>
                        </div> -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else class="text-center" style="line-height: 100vh">
                No workers available..
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueApexCharts from "vue3-apexcharts";
import Progressbar from "@/components/Progressbar";
import BarChart from "@/components/charts/BarChart";
import LineChart from "@/components/charts/LineChart";
import { onActivated, onDeactivated } from "vue";

export default {
  name: "DataflowView",
  // eslint-disable-next-line vue/no-unused-components
  components: { LineChart, BarChart, Progressbar, VueApexCharts },
  data: function () {
    return {
      workers: [],
      componentActive: true,
    };
  },
  watch: {
    "$store.state.workers": {
      handler: function (nv) {
        if (nv != null) {
          this.workers = nv;
        }
      },
      immediate: true,
      deep: true,
    },
    "$store.state.workerTelemetry": {
      handler: function (nv) {
        if (nv != null) {
          console.log("Telemetry was updated!");
          this.updateTelemetry(nv);
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.updateTelemetry(this.$store.state.workerTelemetry);
  },
  methods: {
    updateTelemetry: function (telemetry) {
      telemetry.forEach((data, workerId) => {
        const worker = this.workers.find((worker) => worker.id === workerId);
        if (worker) {
          worker.telemetry = data;
        }
      });
    },
    createChart: function (display_name) {
      const options = {
        chart: {
          type: "radialBar",
          foreColor: "white",
          width: "100%",
          redrawOnParentResize: true,
          animations: {
            enabled: false,
          },
        },
        plotOptions: {
          radialBar: {
            hollow: {
              size: "70%",
            },
            startAngle: -135,
            endAngle: 135,
            dataLabels: {
              name: {
                fontSize: "16px",
                color: undefined,
                offsetY: 80,
              },
              value: {
                offsetY: 0,
                fontSize: "22px",
                color: undefined,
                formatter: function (val) {
                  return val + "%";
                },
              },
            },
          },
        },
        colors: ["#e6d220"],
        labels: [display_name],
      };
      return options;
    },
  },
  created() {
    onActivated(() => {
      window.dispatchEvent(new Event("resize"));
      this.componentActive = true;
    });
    onDeactivated(() => {
      this.componentActive = false;
    });
  },
  computed: {
    configurableWorkers() {
      return this.workers;
    },
  },
};
</script>

<style scoped lang="scss">
h2 {
  &.title {
    width: 100%;
    text-align: center;
  }
}

.spacing {
  margin-bottom: 10px;
  padding: 15px;
}

/* Tabs */
.wrapper {
  width: 100%;
  height: 100%;
  margin: 0 auto;
}
.tabs {
  position: relative;
  background: #d2a52b;
  height: 100%;
}
.tabs::before,
.tabs::after {
  content: "";
  display: table;
}
.tabs::after {
  clear: both;
}
.tab {
  float: left;
}
.tab-switch {
  display: none;
}
.tab-label {
  position: relative;
  display: block;
  line-height: 2.75em;
  height: 3em;
  padding: 0 1.618em;
  background: #d2a52b;
  border-right: 0.125rem solid #a38225;
  color: #fff;
  cursor: pointer;
  top: 0;
}
.tab-label:hover {
  background: #a38225;
}
.tab-content {
  height: calc(100% - 2.5em);
  width: 100%;
  position: absolute;
  z-index: 1;
  top: 2.75em;
  left: 0;
  background: var(--color-primary);
  color: #2c3e50;
  border-bottom: 0.25rem solid #bdc3c7;
  opacity: 0;
}
.tab-switch:checked + .tab-label {
  background: #a38225;
  color: #ffff;
  border-bottom: 0;
  z-index: 1;
  top: -0.0625rem;
}
.tab-switch:checked + label + .tab-content {
  z-index: 2;
  opacity: 1;
}
</style>