<template>
  <div class="container-fluid h-100">
    <div class="row h-100">
      <div class="col-4 h-100 no-padding">
        <div class="d-flex flex-column h-100">
          <div class="h-75">
            <h1>Map Layer Settings</h1>
            <div class="table-scroll">
              <e-table
                :values="tableData"
                v-on:layer-remove="removeOverlay($event)"
                v-on:layer-color="changeOverlayColor($event)"
                v-on:layer-zoom="changeOverlayZoom($event)"
              />
            </div>
          </div>
          <div class="h-20">
            <div class="d-flex flex-row flex-wrap h-40 justify-content-center">
              <e-button
                @click="drawCaptureArea"
                text="Create capture area"
                large
                :active="isDrawing"
              />
              <e-button @click="importOverlay" text="Import overlays" large />
              <e-button
                @click="exportOverlays"
                text="Export to GeoJSON"
                large
              />
              <input
                @change="importOverlayChange($event)"
                id="fileUpload"
                type="file"
                accept=".geojson,.zip"
                hidden
              />
            </div>
          </div>
          <div class="text-block h-20 theme-light">
            <span id="text-block-content"> Project information </span>
          </div>
        </div>
      </div>

      <div class="col h-100">
        <div class="d-flex flex-column h-100">
          <div class="flex-grow-1 h-75">
            <e-map mapname="map" ref="map" />
          </div>
          <control-bar
            @placeIcon="placeIcon($event)"
            :placing="isPlacing"
            hasmap
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ControlBar from "@/components/ControlBar";
import EButton from "@/components/Button";
import EMap from "@/components/Map";
import ETable from "@/components/Table";
import shp from "shpjs";
import Notification from "@/assets/js/notification.js";
import Utility from "@/assets/js/utility.js";
import { onActivated } from "vue";

export default {
  name: "ProjectInfoView",
  components: { EButton, EMap, ETable, ControlBar },
  data: function () {
    return {
      zoom: 12,
      tableData: this.$store.state.tableData,
      isDrawing: false,
      isPlacing: "",
    };
  },
  created() {
    onActivated(() => {
      window.dispatchEvent(new Event("resize"));
    });
  },
  methods: {
    removeOverlay: function (overlay) {
      this.$store.commit("removeImportedShapeByName", overlay);
      this.$store.commit("deleteTableData", overlay.layer);
    },
    changeOverlayColor: function (overlay) {
      this.$store.commit("setShapeLayerStyling", overlay);
    },
    changeOverlayZoom: function (overlay) {
      if (overlay.min) {
        this.$store.commit("setShapeLayerMinZoom", overlay);
      } else {
        this.$store.commit("setShapeLayerMaxZoom", overlay);
      }
    },
    importOverlay: function () {
      document.getElementById("fileUpload").click();
    },
    exportOverlays: function () {
      if (this.$store.getters.getImportedShapesCount > 0) {
        const shapes = this.$store.getters.getImportedShapes;
        shapes.forEach((shape, name) => {
          Utility.downloadToClient(
            shape,
            `${name.replace(/\.[^/.]+$/, "")}.geojson`,
            "text/plain"
          );
        });
      } else {
        Notification.showMessageDialog(
          "warning",
          "",
          "There are no layers to export"
        );
      }
    },
    placeIcon: function (type) {
      // Clean capture area
      this.isDrawing = false;
      this.$refs.map.stopInteraction();

      if (this.isPlacing !== type) {
        this.isPlacing = type;
        this.$refs.map.setTooltip(
          `Click on the map to place a '${type.replace(/_/g, ' ')}' icon`
        );
        this.$refs.map.startInteraction((feature) => {
          // We need to add the icon to the GEOJSON for rendering purposes (in 3rd party GIS applications)
          if (feature.features[0].properties == null) {
            feature.features[0].properties = { icon: type };
          } else {
            feature.features[0].properties.icon = type;
          }

          const name = "Remarks";
          this.$store.commit("setImportedShapes", {
            name: name,
            geojson: feature,
          });
          this.$store.commit("setTableData", {
            name: name,
            properties: [name, 1, "Red", "4", "Delete"],
          });
          this.$refs.map.setTooltip("");
          this.isPlacing = "";
        }, "Point");
      } else {
        this.$refs.map.setTooltip("");
        this.isPlacing = "";
        this.$refs.map.stopInteraction();
      }
    },
    drawCaptureArea: function () {
      // Clean icon placement
      this.isPlacing = "";
      this.$refs.map.stopInteraction();

      this.isDrawing = !this.isDrawing;
      if (this.isDrawing) {
        this.$refs.map.setTooltip("Click on the map to start your interaction");
        this.$refs.map.startInteraction((feature) => {
          console.log(feature);

          Notification.showInputDialog().then((name) => {
            if (name) {
              this.$store.commit("setImportedShapes", {
                name: name,
                geojson: feature,
              });
              this.$store.commit("setTableData", {
                name: name,
                properties: [name, 1, "Red", "4", "Delete"],
              });
            }
            this.$refs.map.setTooltip("");
            this.isDrawing = false;
          });
        }, "Polygon");
      } else {
        this.$refs.map.setTooltip("");
        this.$refs.map.stopInteraction();
      }
    },
    importOverlayChange: function (file) {
      let reader = new FileReader();
      const self = this;

      reader.onload = function (event) {
        const fileType = event.target.result.split(":").pop().split(";")[0];
        const base64 = event.target.result.split(",")[1];
        const fileName = file.target.files[0].name.replace(/\.[^/.]+$/, "");

        if (fileType === "application/x-zip-compressed") {
          let str = atob(base64); // creates a ASCII string
          let buffer = new ArrayBuffer(str.length),
            view = new Uint8Array(buffer);
          for (var i = 0; i < str.length; i++) {
            view[i] = str.charCodeAt(i);
          }
          shp(buffer).then(function (geojson) {
            if (Array.isArray(geojson)) {
              console.warn(
                "Arrays of FeatureCollections are currently not supported. Your .zip should only contain a single shape file!"
              );
              return;
            }
            self.$store.commit("setImportedShapes", {
              name: fileName,
              geojson: Object.freeze(geojson),
            });
            self.$store.commit("setTableData", {
              name: fileName,
              properties: [
                fileName,
                geojson.features.length,
                "Red",
                "4",
                "Delete",
              ],
            });
          });
        } else if (
          fileType === "application/octet-stream" ||
          fileType === "application/json"
        ) {
          const parsed = JSON.parse(atob(base64));
          if (Array.isArray(parsed)) {
            console.warn(
              "Arrays of FeatureCollections are currently not supported. Your geojson should only contain a single FeatureCollection!"
            );
            return;
          }
          console.log(parsed);
          self.$store.commit("setImportedShapes", {
            name: fileName,
            geojson: Object.freeze(parsed),
          });
          self.$store.commit("setTableData", {
            name: fileName,
            properties: [
              fileName,
              parsed.features.length,
              "Red",
              "4",
              "Delete",
            ],
          });
        } else {
          console.error("File not supported!");
          return;
        }
      };

      if (file.target.files[0] instanceof Blob) {
        reader.readAsDataURL(file.target.files[0]);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.text-block {
  background-color: white;
  color: var(--font-color);
  padding: 15px;
  font-family: "PT Sans", sans-serif;
}

.temp-width {
  width: 20%;
}

#dummy-bg {
  display: block;
  background: url(~@/assets/img/softwarePreview.png);
  background-size: cover;
}

table {
  border-collapse: collapse;
}

td,
th {
  border: 1px solid #999;
  padding: 0.5rem;
  text-align: left;
}

.table-scroll {
  overflow-y: auto;
  height: 85%;
}
</style>