<template>
  <img v-bind:class="{centermiddle: nav, iconfix: icon == 'camera'}" :src="getSvg()" fill="#FFFFFF"/>
</template>

<script>

export default {
  name: "EIcon",
  props: {
    nav: Boolean,
    icon: String,
  },
  methods: {
    getSvg() {
      if (this.icon !== undefined) {
        return require('@/assets/icons/' + this.icon + ".svg");
      } else {
        return "";
      }
    }
  }
}
</script>

<style scoped>
.centermiddle {
  margin-top: 55%;
  margin-right: 20px;
}

.iconfix {
  margin-top: 60% !important;
}
</style>