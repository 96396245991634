import Swal from "sweetalert2";

const protectedLayers = ['GPS', 'Remarks'];
export default class Notification {
    static show(type, title, timeout = 5000) {
        const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: timeout,
            timerProgressBar: true,
            didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
        });

        Toast.fire({
            icon: type,
            title: title,
        });
    }

    static showMessageDialog(type, title, text, textOnly = false) {
        Swal.fire({
            icon: type,
            title: title,
            text: text,
            showCancelButton: !textOnly,
            showConfirmButton: !textOnly,
            allowOutsideClick: !textOnly,
            // footer: '<a href>Why do I have this issue?</a>'
        });
    }

    static async showInputDialog() {
        const { value: result } = await Swal.fire({
            title: 'Enter a name',
            input: 'text',
            inputLabel: 'Your layer name\n(You can also add to an existing layer)',
            inputPlaceholder: 'layer',
            showCancelButton: true,
            inputValidator: (value) => {
                if (!value) {
                    return 'You need to write something!';
                }
                if (protectedLayers.filter((pl) => pl.toUpperCase() == value.toUpperCase()).length > 0) {
                    return `Sorry, ${value} is a protected layer!`;
                }
            }
        });
        return result;
    }

    static async showKeyDialog(title = 'Enter your license key') {
        const { value: result } = await Swal.fire({
            title: title,
            input: 'text',
            inputLabel: 'The recorder has not been activated yet. Please enter your license key to activate the product',
            inputPlaceholder: 'XXXXX-XXXXX-XXXXX-XXXXX-XXXXX',
            confirmButtonText: 'Activate',
            confirmButtonColor: '#d2a52b',
            allowOutsideClick: false,
            inputValidator: async (value) => {
                if (!value) {
                    return 'Please enter a license key!';
                }
                if (!value.match(/^(.{5}-){4}.{5}$/g)) {
                    return 'Invalid key format!';
                }
            }
        }).then((result) => {
            // API call
            const ip = this.getHubIP() || 'http://127.0.0.1:3000';
            let request = new XMLHttpRequest();
            request.open('GET', `${ip}/activate/${result.value}`);
            request.send();
            Notification.showMessageDialog('info', '', 'Validating...', true);
            request.onload = () => {
                if (request.status == 200) {
                    Notification.showMessageDialog('success', '', 'Your product was successfully activated!');
                } else {
                    Notification.showKeyDialog('Try again..');
                }
            }
        });
        return result;
    }

    static getHubIP() {
        if (navigator.userAgent.toLowerCase().indexOf(' electron/') != -1) {
            const fs = require('fs');
            if (fs.existsSync('user.config')) {
                console.log('User config exists!');
                const data = fs.readFileSync('user.config', 'utf-8');
                if (data) {
                    const ip = data.split('=');
                    if (ip[0] === 'HUB_IP') {
                        console.log(data);
                        return ip[1];
                    }
                }
            }
        }
        return null;
    }
}