<template>
  <div class="container-fluid h-100">
    <div class="row h-100">
      <div class="col h-100 no-padding">
        <div class="d-flex flex-column h-100">
          <div class="h-100">
            <div class="wrapper">
              <div v-if="configurableWorkers.length > 0 || triggerBoxUrl" class="tabs">
                <div v-if="triggerBoxUrl" class="tab">
                  <input
                    type="radio"
                    name="css-tabs"
                    id="tab--1"
                    checked
                    class="tab-switch"
                  />
                  <label for="tab--1" class="tab-label">GeoTagger</label>
                  <div class="tab-content">
                    <iframe
                      class="embed"
                      :src="triggerBoxUrl"
                      title="GeoTagger"
                      >I'm sorry, browser not compatible</iframe
                    >
                  </div>
                </div>
                <div
                  v-for="(tab, idx) in configurableWorkers"
                  :key="tab"
                  class="tab"
                >
                  <input
                    type="radio"
                    name="css-tabs"
                    :id="'tab-' + idx"
                    :checked="!triggerBoxUrl && idx == 0"
                    class="tab-switch"
                  />
                  <label :for="'tab-' + idx" class="tab-label">{{
                    tab.display_name
                  }}</label>
                  <div class="tab-content">
                    <iframe
                      class="embed"
                      :src="tab.config_url"
                      :title="tab.display_name"
                      >I'm sorry, browser not compatible</iframe
                    >
                  </div>
                </div>
              </div>
              <div v-else class="text-center" style="line-height: 100vh">
                No configuration pages available..
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BrowserView",
  data() {
    return {
      workers: [],
      triggerBoxUrl: this.$store.state.triggerBoxUrl,
    };
  },
  watch: {
    "$store.state.workers": {
      handler: function (nv) {
        if (nv != null) {
          console.log("NEW WORKERS ARRIVED, ALL RISE FOR THE BROWSER!");
          console.log(nv);
          this.workers = nv;
        }
      },
      immediate: true,
      deep: true,
    },
    "$store.state.triggerBoxUrl": {
      handler: function (nv) {
        if (nv != null) {
          this.triggerBoxUrl = this.$store.state.triggerBoxUrl;
        }
      },
      immediate: true,
    },
  },
  computed: {
    configurableWorkers() {
      return this.workers.filter((worker) => worker.config_url);
    },
  },
};
</script>

<style scoped>
.wrapper {
  width: 100%;
  height: 100%;
  margin: 0 auto;
}
.tabs {
  position: relative;
  background: #d2a52b;
  height: 100%;
}
.tabs::before,
.tabs::after {
  content: "";
  display: table;
}
.tabs::after {
  clear: both;
}
.tab {
  float: left;
}
.tab-switch {
  display: none;
}
.tab-label {
  position: relative;
  display: block;
  line-height: 2.75em;
  height: 3em;
  padding: 0 1.618em;
  background: #d2a52b;
  border-right: 0.125rem solid #a38225;
  color: #fff;
  cursor: pointer;
  top: 0;
}
.tab-label:hover {
  background: #a38225;
}
.tab-content {
  height: calc(100% - 2.5em);
  width: 100%;
  position: absolute;
  z-index: 1;
  top: 2.75em;
  left: 0;
  background: #fff;
  color: #2c3e50;
  border-bottom: 0.25rem solid #bdc3c7;
  opacity: 0;
}
.tab-switch:checked + .tab-label {
  background: #a38225;
  color: #ffff;
  border-bottom: 0;
  z-index: 1;
  top: -0.0625rem;
}
.tab-switch:checked + label + .tab-content {
  z-index: 2;
  opacity: 1;
}
.embed {
  width: 100%;
  height: 100%;
  border: none;
}
</style>