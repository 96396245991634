<template>
  <div id="app">
    <div v-if="!socketConnnected" class="not-connected">
      We weren't able to connect to the server, displayed data may be outdated!
      Please make sure that the server is online and reachable.
    </div>
    <div class="row">
      <div class="no-padding">
        <nav-bar />
      </div>
      <div class="col no-padding" style="overflow: hidden">
        <router-view v-slot="{ Component }">
          <keep-alive include="MapView,ProjectInfoView,DataflowView,BrowserView">
            <component :is="Component" />
          </keep-alive>
        </router-view>
      </div>
    </div>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar";
import Notification from "@/assets/js/notification.js";

export default {
  name: "App",
  components: {
    NavBar,
  },
  mounted() {
    // Check if software is activated
    const ip = this.getHubIP() || "http://127.0.0.1:3000";
    let request = new XMLHttpRequest();
    request.open("GET", `${ip}/activated`);
    request.send();
    request.onload = () => {
      if (request.status != 200) {
        Notification.showKeyDialog();
      }
    };
  },
  computed: {
    socketConnnected() {
      return this.$store.state.socketConnection;
    },
  },
  sockets: {
    connect: function () {
      console.log("socket connected");
      this.$store.commit("setSocketConnectionStateChange", { connected: true });
      // Notification.show("success", "Succesfully (re)connected to the server!");
      const self = this;

      this.$socket.emit(
        "register",
        {
          type: "gui",
        },
        function (callback) {
          console.log(callback);
        }
      );

      this.$socket.emit("gui_fetch_workers", function (callback) {
        console.log("FETCHED MANUALLY");
        self.$store.commit("setCurrentWorkers", callback.data);
      });
    },
    disconnect: function () {
      // Notification.show("error", "Connection to server was lost!", 7500);
      this.$store.commit("setSocketConnectionStateChange", {
        connected: false,
      });
    },
    // eslint-disable-next-line no-unused-vars
    hub_push_triggerbox_data: function (data) {
      // Build GEOJSON, then commit
      const geojson = {
        type: "FeatureCollection",
        features: [
          {
            type: "Feature",
            geometry: {
              type: "Point",
              coordinates: [parseFloat(data.lon), parseFloat(data.lat)],
            },
            properties: null,
          },
        ],
      };

      // Commit it
      this.$store.commit("setImportedShapes", {
        name: "GPS",
        geojson: geojson,
      });
      this.$store.commit("setTableData", {
        name: "GPS",
        properties: ["GPS", 1, "Red", "4", "Delete"],
      });
    },
  },
  watch: {
    $route() {
      document.title = "Mobile Mapping Systems";
    },
  },
  methods: {
    getHubIP: function () {
      if (navigator.userAgent.toLowerCase().indexOf(" electron/") != -1) {
        const fs = require("fs");
        if (fs.existsSync("user.config")) {
          console.log("User config exists!");
          const data = fs.readFileSync("user.config", "utf-8");
          if (data) {
            const ip = data.split("=");
            if (ip[0] === "HUB_IP") {
              console.log(data);
              return ip[1];
            }
          }
        }
      }
      return null;
    },
  },
};
</script>

<style type="scss">
.app {
  height: 100%;
}

.not-connected {
  width: 100vw;
  height: 35px;
  line-height: 35px;
  text-align: center;
  position: fixed;
  bottom: 0;
  z-index: 9999;
  background: #ff0033;
}
</style>
