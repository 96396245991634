<template>
  <div class="flex-grow-1 h-100">
    <e-map mapname="map-child" ref="map" />
  </div>
</template>

<script>
import EMap from "@/components/Map";
import { onActivated } from "vue";

export default {
  name: "MapsChildView",
  components: { EMap },
  data: function () {
    return {
      zoom: 16,
    };
  },
  created() {
    onActivated(() => {
      window.dispatchEvent(new Event("resize"));
    });
  },
  methods: {
    stopMapInteraction: function () {
      this.$refs.map.stopInteraction();
    },
    setMapTooltip: function (text) {
      this.$refs.map.setTooltip(text);
    },
    startMapInteraction: function (callback, type, svg = undefined) {
      this.$refs.map.startInteraction(callback, type, svg);
    },
  },
};
</script>

<style scoped>
</style>