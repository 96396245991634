<template>
  <div class="button justify-content-center">
    <div
      class="button-square"
      v-bind:class="{
        active: activeState,
        rounded: rounded,
        large: large,
        small: small,
        recording: activeRecord,
      }"
      v-on:click="onClick"
    >
      <div class="container-fluid h-100">
        <div class="row h-100">
          <div
            class="col-12 align-self-center justify-content-center text-center"
          >
            <div v-if="icon === undefined">
              {{ text }}
            </div>
            <div v-else v-bind:class="activeRecord ? 'large-icon' : 'icon'">
              <e-icon v-if="activeRecord" icon="spinner" />
              <e-icon v-else :icon="icon" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="icon !== undefined"
      id="subtitle"
      class="text-center justify-content-center"
    >
      <section v-if="activeRecord">Recording...</section>
      <section v-else>{{ text }}</section>
    </div>
  </div>
</template>

<script>
import EIcon from "@/components/Icon";

export default {
  name: "e-button",
  components: { EIcon },
  props: {
    modelValue: String,
    valueKey: String, // Used to identify this specific button (in case of grouping)
    text: String,
    icon: String,
    rounded: Boolean,
    large: Boolean,
    small: Boolean,
    active: Boolean,
    activeRecord: Boolean,
  },
  emits: ["onclick", "update:modelValue"],
  methods: {
    onClick: function () {
      if (this.valueKey !== undefined) {
        this.$emit("update:modelValue", this.valueKey);
      } else {
        this.$emit("onclick", this.active);
      }
    },
  },
  computed: {
    activeState() {
      if (this.modelValue !== undefined) {
        return this.modelValue === this.valueKey;
      }

      if (this.active !== undefined) {
        return this.active;
      }

      return false;
    },
  },
};
</script>

<style scoped lang="scss">
body {
  &.theme-dark {
    .button-square {
      border: 1px solid #393939;
    }
  }
}

.button {
  display: inline-block;
  margin: 10px;
  user-select: none;
}

.button-square {
  background: var(--color-primary);
  display: block;
  width: 6vw;
  height: 6vw;
  border-radius: 24px;
  box-shadow: var(--button-shadow);
  font-size: 1vw;
  overflow-wrap: anywhere;

  &:hover {
    cursor: pointer;
    background: var(--button-hover-background);
  }

  &.active {
    background: var(--color-accent);
    .icon {
      filter: brightness(0) invert(1);
    }
  }

  &:active {
    background: var(--color-accent);
    .icon {
      filter: brightness(0) invert(1);
    }
  }

  &.recording {
    background: var(--color-accent);
    pointer-events: none;
  }

  &.rounded {
    border-radius: 50%;
  }

  &.large {
    width: 9vw !important;
  }

  &.small {
    height: 2vw !important;
    border-radius: 12px !important;
  }

  .icon {
    img {
      width: 50%;
    }
  }

  .large-icon {
    img {
      width: 100%;
    }
  }
}

#subtitle {
  width: 100%;
  text-align: center;
  margin-top: 10px;
}
</style>