import { createStore } from 'vuex';

export default createStore({
    state: {
        /* CONNECTIVITY */
        socketConnection: false,

        /* MAP RELATED STATES */
        importedShapes: new Map(),
        importedShapesColor: new Map(),
        importedShapesMinZoom: new Map(),
        importedShapesMaxZoom: new Map(),
        importedIcons: [],
        tableData: new Map(),

        /* WORKER RELATED STATE */
        workers: [],
        workerTelemetry: new Map(),
        triggerBoxUrl: '',

        /* CONTROLS RELATED STATE */
        isRecording: false,
    },
    getters: {
        /* MAP RELATED GETTERS */
        getImportedShapes: (state) => {
            return state.importedShapes;
        },
        getImportedShapesCount: (state) => {
            return state.importedShapes.size;
        }
    },
    mutations: {
        /* CONNECTIVITY */
        setSocketConnectionStateChange(state, data) {
            state.socketConnection = data.connected;
        },

        /* MAP RELATED MUTATIONS */
        setImportedShapes(state, data) {
            if (state.importedShapes.has(data.name)) {
                const newGeo = data.geojson.features;
                const oldGeo = JSON.parse(JSON.stringify(state.importedShapes.get(data.name).features));
                data.geojson.features = oldGeo.concat(newGeo);
            }
            state.importedShapes.set(data.name, data.geojson);
        },
        // eslint-disable-next-line no-unused-vars
        setShapeLayerStyling(state, data) {
            state.importedShapesColor.set(data.layer, data.color);
        },
        setShapeLayerMinZoom(state, data) {
            state.importedShapesMinZoom.set(data.layer, data.zoom);
        },
        setShapeLayerMaxZoom(state, data) {
            state.importedShapesMaxZoom.set(data.layer, data.zoom);
        },
        clearImportedShapes(state) {
            state.importedShapes.clear();
        },
        removeImportedShapeByName(state, data) {
            state.importedShapes.delete(data.layer);
        },
        setTableData(state, data) {
            if (!state.tableData.has(data.name)) {
                state.tableData.set(data.name, data.properties);
            }
        },
        deleteTableData(state, data) {
            state.tableData.delete(data);
        },

        /* WORKER RELATED MUTATIONS */
        setCurrentWorkers(state, data) {
            state.workers = data;
        },

        /* CONTROLS RELATED MUTATIONS */
        setRecording(state, recording) {
            state.isRecording = recording;
        },

        /* SOCKET RELATED ACTIONS */
        "SOCKET_hub_push_gui_storage"(state, data) {
            console.log(`Server said: `);
            console.log(data);
            state.workers = data;
        },

        "SOCKET_hub_gui_start_record_animation"(state) {
            state.isRecording = true;
        },

        "SOCKET_hub_gui_stop_record_animation"(state) {
            state.isRecording = false;
        },

        "SOCKET_hub_push_gui_telemetry"(state, data) {
            data.timestamp = new Date(Date.now()).toISOString() + "+01:00"; // Add timezone for NL time :D
            state.workerTelemetry.set(data.id, data);
        },

        "SOCKET_hub_push_triggerbox_url"(state, data) {
            state.triggerBoxUrl = data.config_url;
        }
    },
});
