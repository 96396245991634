<template>
  <div>
    <div class="d-flex flex-column h-100">
      <span>{{ display }}</span><br/>
      <span>{{ max }}</span>
      <input
        class="flex-grow-1"
        type="range"
        orient="vertical"
        :min="min"
        :max="max"
        :step="step"
        v-model="input_value"
        @change="valueChange"
        :disabled="!socketConnnected || (preventRecordingChange && isRecording)"
      />
      <span>{{ min }}</span
      ><br />
      <input class="input-styled" type="number" @change="valueChange" :min="min" :max="max" :value="input_value"><br />
    </div>
  </div>
</template>

<script>
export default {
  name: "EVerticalSlider",
  props: ["display", "min", "max", "step", "value", "idleChangeOnly"],
  emits: ["updatedvalue"],
  data() {
    return {
      input_value: this.value,
    };
  },
  computed: {
    socketConnnected() {
      return this.$store.state.socketConnection;
    },
    preventRecordingChange() {
      return this.idleChangeOnly == true || this.idleChangeOnly == 'true';
    },
    isRecording() {
      return this.$store.state.isRecording;
    },
  },
  methods: {
    valueChange: function (event) {
      const valueToCompare = event.target.value;
      if (Number(valueToCompare) <= Number(event.target.min)) {
        event.target.value = event.target.min;
      }
      if (Number(valueToCompare) >= Number(event.target.max)) {
        event.target.value = event.target.max;
      }
      
      this.$emit("updatedvalue", event.target.value);
    },
  },
};
</script>

<style scoped lang="scss">
$track-color: #eceff1 !default;
$thumb-color: #607d8b !default;

input[type="range"][orient="vertical"] {
  writing-mode: bt-lr; /* IE */
  -webkit-appearance: slider-vertical; /* WebKit */
  height: 100%;
}

.input-styled {
  width: 100%;
  border: 1px solid;
  border-radius: 0.25em;
  padding: 0.25em 0.5em;
  font-size: 1rem;
  cursor: pointer;
  background-color: #fff;
  background-image: linear-gradient(to top, #f9f9f9, #fff 33%);
  display: grid;
}
</style>