<template>
  <table>
    <thead>
      <tr>
        <th v-for="column in columns" :key="column">{{ column }}</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="layer in compValues" :key="layer">
        <!-- Determine the layer name -->
        <td class="text" v-bind:class="{ gps: layer[0] === 'GPS' || layer[0] === 'Remarks' }">
          {{ layer[0] }}
        </td>
        <!-- <td>{{ layer[1] }}</td> -->
        <td>
          <select @change="changeColor(layer[0], $event.target.value)">
            <option disabled value="">Please select a color</option>
            <option>Blue</option>
            <option>Red</option>
            <option>Green</option>
          </select>
        </td>
        <td>
          <input
            @change="changeMinZoom(layer[0], $event.target.value)"
            type="number"
            min="2"
            max="20"
            value="2"
          />
        </td>
        <td>
          <input
            @change="changeMaxZoom(layer[0], $event.target.value)"
            type="number"
            min="2"
            max="20"
            value="10"
          />
        </td>
        <td>
          <button @click="removeLayer(layer[0])">Remove</button>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name: "ETable",
  props: ["values"],
  emits: ["layer-remove", "layer-color", "layer-zoom"],
  data: function () {
    return {
      columns: [
        "Layer",
        /*"Features",*/ "Color",
        "Min Zoom",
        "Max Zoom",
        "Action",
      ],
    };
  },
  computed: {
    compValues: function () {
      return this.values.values();
    },
  },
  mounted() {
    // this.data.push(["Test", "6000", "Red", "4", "No"]);
  },
  methods: {
    removeLayer: function (layer) {
      this.$emit("layer-remove", { layer: layer });
    },
    changeColor: function (layer, newColor) {
      let color = {};
      if (newColor === "Red") {
        color.r = 255;
        color.g = 0;
        color.b = 0;
      } else if (newColor === "Green") {
        color.r = 0;
        color.g = 255;
        color.b = 0;
      } else if (newColor === "Blue") {
        color.r = 0;
        color.g = 0;
        color.b = 255;
      }
      this.$emit("layer-color", { layer: layer, color: color });
    },
    changeMinZoom: function (layer, newZoom) {
      this.$emit("layer-zoom", { layer: layer, zoom: newZoom, min: true });
    },
    changeMaxZoom: function (layer, newZoom) {
      this.$emit("layer-zoom", { layer: layer, zoom: newZoom, min: false });
    },
  },
};
</script>

<style lang="scss" scoped>
.disabled {
  pointer-events: none;
  opacity: 0.6;
}

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;

  .text {
    font-weight: bold;
  }

  .gps {
    color: var(--color-accent);
  }

  td,
  th {
    padding: 8px;
    border: 1px solid #949494;
  }

  th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: var(--color-accent);
    color: white;
  }

  select,
  input,
  button {
    width: 100%;
    border: 1px solid;
    border-radius: 0.25em;
    padding: 0.25em 0.5em;
    font-size: 1rem;
    cursor: pointer;
    background-color: #fff;
    background-image: linear-gradient(to top, #f9f9f9, #fff 33%);
    display: grid;
  }
}
</style>