<template>
  <div style="height: 100%">
    <div class="container h-100" style="display: inline">
      <div class="row h-100 overflow-row">
        <div
          v-for="optionRow in optionOptions"
          :key="optionRow"
          class="col-6 overflow-col"
        >
          <e-dropdown
            style="margin-bottom: 15px"
            v-for="option in optionRow"
            :key="option"
            :display="option.display_name"
            :params="option.properties.params"
            :value="option.properties.value"
            :idle-change-only="option.idle_change_only"
            @updatedvalue="updateSetting(option.name, $event)"
          />
        </div>
        <div
          v-for="option in rangeOptions"
          :key="option"
          class="col-4 overflow-col"
        >
          <e-vertical-slider
            :display="option.display_name"
            :min="option.properties.min"
            :max="option.properties.max"
            :step="option.properties.step"
            :value="option.properties.value"
            :idle-change-only="option.idle_change_only"
            @updatedvalue="updateSetting(option.name, $event)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EVerticalSlider from "@/components/VerticalSlider";
import EDropdown from "@/components/Dropdown";
import Joi from "joi";

const rangeSchema = Joi.object({
  min: Joi.string().required(),
  max: Joi.string().required(),
  step: Joi.string(),
  value: Joi.string().required(),
});

const optionSchema = Joi.object({
  params: Joi.array().items(Joi.string().required()).min(2).required(),
  value: Joi.string().required(),
});

export default {
  name: "ESettingvisualisation",
  props: ["data"],
  emits: ["settingupdated"],
  // eslint-disable-next-line vue/no-unused-components
  components: { EVerticalSlider, EDropdown },
  computed: {
    rangeOptions() {
      return this.getOptions().filter((option) => option.type === "range");
    },
    optionOptions() {
      const options = this.getOptions().filter(
        (option) => option.type === "options"
      );
      const array_chunks = (array, chunk_size) =>
        Array(Math.ceil(array.length / chunk_size))
          .fill()
          .map((_, index) => index * chunk_size)
          .map((begin) => array.slice(begin, begin + chunk_size));

      return array_chunks(options, 4);
    },
  },
  methods: {
    updateSetting: function (setting, value) {
      console.log(`${setting} -> ${value}`);
      this.$emit("settingupdated", { setting: setting, value: value });
    },
    getOptions() {
      let data = this.data;
      data.forEach((option) => {
        // Test for range
        let validation = rangeSchema.validate(option.properties);
        if (!validation.error) {
          option.type = "range";
          // Round value
          option.properties.min = Math.round(option.properties.min * 10) / 10;
          option.properties.max = Math.round(option.properties.max * 10) / 10;
          // option.properties.value = Math.round(option.properties.value * 10) / 10;
          console.log(option.properties.max);
        }

        // Test for option
        validation = optionSchema.validate(option.properties);
        if (!validation.error) {
          console.log("its option");
          option.type = "options";
        }
      });
      return data;
    },
  },
};
</script>

<style scoped>
.spaced {
  width: 175px;
}

.center-text {
  text-align: center;
}

.overflow-row {
  display: block;
  overflow-x: auto;
}

.overflow-col {
  display: inline-block;
  vertical-align: top;
}
</style>