<template>
  <div>
    <div class="d-flex flex-column h-100">
      <label for="drop">{{ display }}</label>
      <select
        style="margin-top: 5px"
        name="drop"
        id="drop"
        v-model="input_value"
        @change="valueChange"
        :disabled="!socketConnnected || (preventRecordingChange && isRecording)"
      >
        <option v-for="option in params" :key="option">{{ option }}</option>
      </select>
    </div>
  </div>
</template>

<script>
export default {
  name: "EDropdown",
  props: ["display", "params", "value", "idleChangeOnly"],
  emits: ["updatedvalue"],
  data() {
    return {
      input_value: this.value,
    };
  },
  computed: {
    socketConnnected() {
      return this.$store.state.socketConnection;
    },
    preventRecordingChange() {
      return this.idleChangeOnly == true || this.idleChangeOnly == "true";
    },
    isRecording() {
      return this.$store.state.isRecording;
    },
  },
  methods: {
    valueChange: function (event) {
      this.$emit("updatedvalue", event.target.value);
    },
  },
};
</script>

<style scoped lang="scss">
select {
  width: 100%;
  border: 1px solid;
  border-radius: 0.25em;
  padding: 0.25em 0.5em;
  font-size: 1rem;
  cursor: pointer;
  background-color: #fff;
  background-image: linear-gradient(to top, #f9f9f9, #fff 33%);
  display: grid;
}
</style>