<template>
  <div class="container-fluid h-100 no-padding">
    <div
      class="d-flex flex-row flex-wrap h-100"
      style="background-color: #212627"
    >
      <p v-if="streams.length == 0" class="no-preview-text">
        No video previews available..
      </p>
      <div class="container-fluid no-margin">
        <div class="row h-100">
          <div
            class="preview-sub"
            v-bind:class="{
              'col-12': streams.length <= 3,
              'col-6': streams.length >= 4,
              'width-50': streams.length == 1,
            }"
            v-for="(stream, index) in streams"
            v-bind:key="stream"
            v-bind:style="{
              background: 'url(' + stream + ') center center no-repeat',
            }"
          >
            <div class="camera-name">
              <div class="text">{{ workers[index].display_name }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Utility from "@/assets/js/utility";
import { onActivated, onDeactivated } from "vue";

export default {
  name: "CamsChildView",
  data() {
    return {
      workers: [],
      workerIds: [],
      aspectRatio: 16 / 9,
      dataStreams: new Map(), // key = workerid & value = img src
    };
  },
  created() {
    onActivated(() => {
      this.startPreview();
    });
    onDeactivated(() => {
      this.stopPreview();
    });
  },
  mounted() {
    const self = this;
    window.addEventListener("resize", function () {
      clearTimeout(window.resizedFinished);
      window.resizedFinished = setTimeout(function () {
        self.startPreview();
      }, 500);
    });

    window.dispatchEvent(new Event("resize"));
  },
  unmounted() {
    this.stopPreview();
  },
  watch: {
    "$store.state.workers": {
      handler: function (nv) {
        if (nv != null) {
          console.log("NEW WORKERS ARRIVED, ALL RISE!");
          console.log(nv);
          this.workers = nv;
          this.updatePreviews();
        }
      },
      immediate: true,
      deep: true,
    },
  },
  sockets: {
    hub_push_gui_preview: function (data) {
      console.log("New img stream");
      // const delta = performance.now() - this.deltaTime;

      this.workers.forEach((worker) => {
        if (worker.id == data.id) {
          if (worker.subtype && worker.subtype.toUpperCase() == "LIDAR") return;

          const isMain = false;
          // if (!isMain && delta > this.maxSubFPS) {
          //   renderSub = true;
          //   this.deltaTime = performance.now();
          // } else if (!isMain) {
          //   return;
          // }

          let arrayBuffer = data.buffer;
          let bytes = new Uint8Array(arrayBuffer);
          const decode = "data:image/jpg;base64," + Utility.encodeBytes(bytes);
          let img = new Image();
          img.src = decode;
          const self = this;
          img.onload = function () {
            if (isMain) {
              self.mainStream.preview = decode;
            } else {
              self.dataStreams.set(worker.id, decode);
            }
          };
        }
      });
    },
  },
  computed: {
    streams() {
      // Check if resolution has changed. If so, update it

      return Array.from(this.dataStreams.values());
    },
  },
  methods: {
    updatePreviews: function () {
      console.log("Call to updatepreview");
      if (this.workers.length > 0) {
        console.log("Call to updatepreview WITH WORKERS");

        // 1) Remove all worker streams that are no longer connected
        const workerIdMap = this.workers.map((worker) => worker.id);
        this.dataStreams.forEach((ds, key) => {
          if (!workerIdMap.includes(key)) {
            this.dataStreams.delete(key);
          }
        });

        // 2) Put all workers in the datastreams
        this.workers.forEach((worker, idx) => {
          this.dataStreams.delete(`placeholder-${idx}`);
          if (!this.dataStreams.has(worker.id)) {
            this.dataStreams.set(
              worker.id,
              "" //"https://via.placeholder.com/285x195"
            );
          }
        });

        // 3) Check if we need to start sending a preview command (new worker introduced)
        const workerMap = this.workers.map((worker) => worker.id);
        for (let i = 0; i < workerMap.length; i++) {
          if (!this.workerIds.includes(workerMap[i])) {
            this.workerIds = workerMap;
            const self = this;
            setTimeout(function () {
              self.startPreview();
            }, 100);
            return;
          }
        }
        for (let i = 0; i < this.workerIds.length; i++) {
          if (!workerMap.includes(this.workerIds[i])) {
            this.workerIds = workerMap;
            const self = this;
            setTimeout(function () {
              self.startPreview();
            }, 100);
            return;
          }
        }
      } else {
        this.dataStreams.clear();
        this.workerIds = [];
      }
    },
    startPreview: function () {
      console.log("Starting preview!");
      if (document.readyState === "complete") {
        // Determine the resolution
        let subSize;
        if (
          document
            ?.getElementsByClassName("preview-sub")[0]
            ?.getBoundingClientRect()
        ) {
          const size = document
            .getElementsByClassName("preview-sub")[0]
            .getBoundingClientRect();
          const width = Math.round(size.height * this.aspectRatio);
          const height = Math.round(size.height);
          console.log(`SUB Width: ${width} - Height: ${height} (16:9)`);
          subSize = { width: width + "", height: height + "" };
        }

        this.workers.forEach((worker) => {
          // Emit the actual command to the worker
          if (subSize) {
            this.$socket.emit("gui_start_preview", {
              id: worker.id,
              fps: "15",
              resolution: subSize,
            });
          }
        });
      }
    },
    stopPreview: function () {
      this.workers.forEach((worker) => {
        this.$socket.emit("gui_stop_preview", { id: worker.id });
      });
    },
  },
};
</script>

<style scoped>
.no-preview-text {
  position: absolute;
  width: 100%;
  bottom: 50%;
  text-align: center;
  font-size: 125%;
}

.camera-name {
  position: absolute;
  width: 100%;
  height: 30px;
  bottom: 0;
  text-align: center;
  background: rgba(0, 0, 0, 0.6);
  margin-left: -15px;
}

.camera-streaming {
  position: absolute;
  width: 100%;
  height: 50px;
  line-height: 50px;
  bottom: calc(50% - 25px);
  color: var(--color-accent);
  text-transform: uppercase;
  text-align: center;
  background: rgba(0, 0, 0, 0.6);
  margin-left: -15px;
}

.camera-name .text {
  line-height: 30px;
}

.width {
  width: 50px;
}

.width-50 {
  width: 50%;
}

.allow-overflow-x {
  overflow-x: auto;
}

.text-fit {
  word-break: break-word;
}
</style>