<template>
  <div
    class="d-flex flex-column justify-content-center h-25 p-2"
    style="margin-right: 3%"
  >
    <div class="d-flex flex-row justify-content-end">
      <div class="ml-auto" />
      <div>
        <e-button
          @click="playRecording"
          rounded
          text="Record"
          icon="play"
          :active-record="recording"
        />
        <e-button @click="pauseRecording" rounded text="Pause" icon="pause" />
        <e-button @click="stopRecording" rounded text="Stop" icon="stop" />
        <!-- <e-button rounded text="Record" icon="record"/> -->
      </div>
      <div v-if="!hasmap" class="ml-auto" />
      <div v-if="hasmap" class="ml-auto">
        <e-button @click="placeIcon('stop_here')" text="Stop here" icon="stop_here" :active="placing == 'stop_here'"/>
        <e-button @click="placeIcon('blocked')" text="Blocked" icon="blocked" :active="placing == 'blocked'"/>
        <e-button @click="placeIcon('construction')" text="Construction" icon="construction" :active="placing == 'construction'"/>
      </div>
    </div>
  </div>
</template>

<script>
import EButton from "@/components/Button";
import Notification from "@/assets/js/notification.js";

export default {
  name: "ControlBar",
  props: {
    hasmap: Boolean,
    placing: String,
  },
  emits: ['placeIcon'],
  components: { EButton },
  data() {
    return {
      recording: false,
    };
  },
  watch: {
    "$store.state.isRecording": {
      handler: function (nv) {
        this.recording = nv;
      },
      immediate: true,
    },
  },
  methods: {
    playRecording: function () {
      if (!this.recording) {
        if (this.$socket.connected) {
          this.$socket.emit("gui_start", { label: "Recording 1" });
          console.log("Start recording event called 'gui_start' was emitted!");
          this.$store.commit("setRecording", true);

          Notification.show("success", "Recording was started!");
        } else {
          Notification.show(
            "error",
            "Couldn't start recording, no connection to server!"
          );
        }
      }
    },
    pauseRecording: function () {
      if (this.recording) {
        if (this.$socket.connected) {
          this.$socket.emit("gui_pause");
          console.log("Pause recording event called 'gui_pause' was emitted!");
        } else {
          Notification.show(
            "error",
            "Couldn't pause recording, no connection to server!"
          );
        }
      }
    },
    stopRecording: function () {
      if (this.recording) {
        if (this.$socket.connected) {
          this.$socket.emit("gui_stop");
          console.log("Stop recording event called 'gui_stop' was emitted!");
          this.$store.commit("setRecording", false);

          Notification.show("success", "Recording was stopped!");
        } else {
          Notification.show(
            "error",
            "Couldn't stop recording, no connection to server!"
          );
        }
      }
    },
    placeIcon: function(type) {
      this.$emit("placeIcon", type);
    },
  },
};
</script>

<style scoped lang="scss">
</style>