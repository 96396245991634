<template>
  <div class="container-fluid h-100">
    <e-workersettings/>
  </div>
</template>

<script>
import EWorkersettings from "@/components/WorkerControls/WorkerSettings";

export default {
  name: "CamsView",
  components: {EWorkersettings},
  data: function () {
    return {zoom: 12}
  }
}
</script>

<style scoped lang="scss">

</style>