/* eslint-disable no-unused-vars */
import proj4 from 'proj4'
import "ol/ol.css";
import Draw from 'ol/interaction/Draw';
import OLMap from "ol/Map";
import Circle from 'ol/geom/Circle';
import Point from 'ol/geom/Point';
import Feature from 'ol/Feature';
import { Cluster, OSM, Vector } from "ol/source";
import { fromLonLat, toLonLat } from "ol/proj";
import GeoJSON from "ol/format/GeoJSON";
import View from "ol/View";
import { defaults as defaultControls } from "ol/control";
import { Circle as CircleStyle, Fill, Stroke, Style, Icon } from "ol/style";
import { Tile as TileLayer, Vector as VectorLayer, Group } from "ol/layer";
import { getCenter } from 'ol/extent';
import { register } from 'ol/proj/proj4';
import { get as getProjection } from 'ol/proj';
import { Projection } from 'ol/proj';

export default class MapManager {
    /**
     * Constructor
     * @param {*} element The div element to which the map is bound 
     */
    constructor(element) {
        this.customLayers = new Map();
        this.gpsLayer = new VectorLayer({
            source: new Vector({}),
            renderMode: 'image',
            style: this._styleFunction.bind(this),
        });
        this.iconLayers = [];

        this.image = new CircleStyle({
            radius: 5,
            fill: new Fill({color: "rgba(30,144,255,1"}),
            stroke: new Stroke({ color: "rgba(30,144,255, 1)", width: 1}),
        });

        this.style = {
            Point: new Style({
                image: this.image,
            }),
            LineString: new Style({
                stroke: new Stroke({
                    color: "green",
                    width: 1,
                }),
            }),
            MultiLineString: new Style({
                stroke: new Stroke({
                    color: "green",
                    width: 1,
                }),
            }),
            MultiPoint: new Style({
                image: this.image,
            }),
            MultiPolygon: new Style({
                stroke: new Stroke({
                    color: "blue",
                    width: 2,
                }),
                fill: new Fill({
                    color: "rgba(0, 0, 255, 0.1)",
                }),
            }),
            Polygon: new Style({
                stroke: new Stroke({
                    color: "blue",
                    width: 2,
                }),
                fill: new Fill({
                    color: "rgba(0, 0, 255, 0.1)",
                }),
            }),
            GeometryCollection: new Style({
                stroke: new Stroke({
                    color: "magenta",
                    width: 2,
                }),
                fill: new Fill({
                    color: "magenta",
                }),
                image: new CircleStyle({
                    radius: 10,
                    fill: null,
                    stroke: new Stroke({
                        color: "magenta",
                    }),
                }),
            }),
            Circle: new Style({
                stroke: new Stroke({
                    color: "red",
                    width: 2,
                }),
                fill: new Fill({
                    color: "rgba(255,0,0,0.2)",
                }),
            }),
        };

        this.map = new OLMap({
            layers: [
                new TileLayer({
                    // source: new OSM({url: 'http://localhost:3000/tile/{z}/{x}/{y}.png'}),
                    source: new OSM(),
                }),
                this.gpsLayer
            ],
            view: new View({
                center: [0, 0],
                zoom: 4,
                projection: 'EPSG:3857'
            }),
            controls: defaultControls({ attribution: false }),
        });

        this.map.on('singleclick', this._singleClickEvent.bind(this));
        this.map.on('moveend', this._moveEndEvent.bind(this));
        this.map.setTarget(element);
    }

    changeLayerColor(layer) {
        layer.forEach((color, layerName) => {
            const newStyle = new Style({
                stroke: new Stroke({
                    color: `rgba(${color.r}, ${color.g}, ${color.b}, 1)`,
                    width: 2,
                }),
                fill: new Fill({
                    color: `rgba(${color.r}, ${color.g}, ${color.b}, 0.1)`,
                }),
                image: new CircleStyle({
                    radius: 5,
                    fill: new Fill({ color: `rgba(${color.r}, ${color.g}, ${color.b}, 1)` }),
                    stroke: new Stroke({ color: `rgba(${color.r}, ${color.g}, ${color.b}, 1)`, width: 1 }),
                }),
            });
            if (this.customLayers.get(layerName)) {
                const currLayer = this.customLayers.get(layerName);
                this.customLayers.get(layerName).setStyle(newStyle);
                currLayer.getSource().getFeatures().forEach((feature) => {
                    if (feature.getProperties().icon) {
                        feature.setStyle(new Style({
                            image: new Icon({
                                src: `icons/${feature.getProperties().icon}.svg`,
                                scale: 0.6,
                                color: `rgba(${color.r}, ${color.g}, ${color.b}, 1)`
                            })
                        }))
                    }
                });
            }
        });
    }

    changeLayerZoom(layer, isMin) {
        console.log('Almost there!');
        console.log(layer);

        layer.forEach((zoom, layerName) => {
            if (this.customLayers.get(layerName)) {
                if (isMin) {
                    this.customLayers.get(layerName).setMinZoom(zoom);
                } else {
                    this.customLayers.get(layerName).setMaxZoom(zoom);
                }
            }
        });
    }

    startInteraction(callback, type, svg = undefined) {
        this.draw = new Draw({
            source: new Vector({ wrapX: false }),
            type: type,
        });
        this.map.addInteraction(this.draw);

        const self = this;
        this.draw.on('drawend', function (event) {
            self.stopInteraction();
            const drawnFeature = event.feature.getGeometry().clone().transform('EPSG:3857', 'EPSG:4326');

            callback(new GeoJSON().writeFeaturesObject([new Feature(drawnFeature)]));
        });
    }

    stopInteraction() {
        this.map.removeInteraction(this.draw);
    }

    addGeoJson(geoJson) {
        // Clear layers that have been removed first
        let skip = [];
        let featureUpdate = [];

        geoJson.forEach((geo, key) => {
            if (this.customLayers.has(key)) {
                // Check if the features are different, because we want to redraw if they are
                const geo1 = JSON.parse(JSON.stringify(geo));
                const geo2 = new GeoJSON().writeFeaturesObject(this.customLayers.get(key).getSource().getFeatures(), {
                    dataProjection: 'EPSG:4326',
                    featureProjection: 'EPSG:3857',
                });

                if (JSON.stringify(geo1.features) !== JSON.stringify(geo2.features)) {
                    featureUpdate.push(key);
                } else {
                    skip.push(key);
                }
            }
        });
        this.customLayers.forEach((layer, key) => {
            if (!geoJson.has(key) && !featureUpdate.includes(key)) {
                this.map.removeLayer(layer);
                this.customLayers.delete(key);
            }
        });

        geoJson.forEach((geo, key) => {
            if (!skip.includes(key)) {
                if (featureUpdate.includes(key)) {
                    const currLayer = this.customLayers.get(key);
                    const newFeature = new GeoJSON({
                        dataProjection: 'EPSG:4326',
                        featureProjection: 'EPSG:3857',
                    }).readFeature(geo.features[geo.features.length - 1])

                    // Upate color
                    if (newFeature.getProperties().icon) {
                        newFeature.setStyle(new Style({
                            image: new Icon({
                                src: `icons/${newFeature.getProperties().icon}.svg`,
                                scale: 0.6,
                                color: this._retrieveColorFromFeature(currLayer),
                            }),
                        }));
                    }

                    currLayer.getSource().addFeature(newFeature);
                } else {
                    let vectorSource = new Vector({
                        features: new GeoJSON({
                            dataProjection: 'EPSG:4326',
                            featureProjection: 'EPSG:3857',
                        }).readFeatures(geo),
                    });
                    let vectorLayer = new VectorLayer({
                        source: vectorSource,
                        renderMode: 'image',
                        style: this._styleFunction.bind(this),
                    });
                    this.map.addLayer(vectorLayer);
                    this.customLayers.set(key, vectorLayer);
                }
            }
        });
    }

    addGPSDataPoint(dataPoint) {
        const point = new Point(
            fromLonLat([dataPoint.lon, dataPoint.lat])
        );
        this.gpsLayer.getSource().addFeature(new Feature(point));
    }

    _singleClickEvent(event) {
        console.log('Map was clicked');

        let feature = this.map.forEachFeatureAtPixel(event.pixel, (feature, layer) => {
            // We can add a condition on a layer to restrict the listener
            return feature;
        });

        if (feature !== undefined) {
            // Something
        }
    }

    _moveEndEvent(event) {
        /* TODO - Loop through layers and decide whether to show or hide based on zoom */


        console.log('Map was moved');
        console.log(Math.round(this.map.getView().getZoom() * 10) / 10);
    }

    _styleFunction(feature) {
        if (feature.getProperties().icon) {
            return new Style({
                image: new Icon({
                    src: `icons/${feature.getProperties().icon}.svg`,
                    scale: 0.6,
                    color: 'blue'
                })
            })
        } else {
            return this.style[feature.getGeometry().getType()];
        }
    }

    _retrieveColorFromFeature(layer) {
        if (layer.getStyle() && typeof layer.getStyle() !== "function") {
            const style = layer.getStyle();
            return style.getImage().getFill().getColor() || style.getStroke().getColor()
        }
        return 'blue';
    }
}