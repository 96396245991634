<template>
  <div>
    <progress id="progress-bar" :value="value" :min="min" :max="max"> 32%</progress>
    <label for="progress-bar" v-if="label !== null">{{ label }}</label>
  </div>
</template>

<script>
export default {
  name: "Progressbar",
  props: {
    min: {type: Number, default: 0},
    max: {type: Number, default: 100},
    value: {type: Number, default: 0},
    label: {type: String, default: null}
  },
  data() {
    return {
      progress: this.value
    }
  }
}
</script>

<style scoped lang="scss">

progress {
  border-radius: 7px;
  width: 100%;
  height: 22px;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
}

progress::-webkit-progress-bar {
  background-color: var(--color-primary-darker);
  border-radius: 7px;
}

progress::-webkit-progress-value {
  background-color: var(--color-accent);
  border-radius: 10px;
}

label[for=progress-bar] {
  float: right;
}
</style>