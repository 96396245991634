<template>
  <div class="container-fluid h-100">
    <div class="row h-100">
      <div class="col-4 h-100 no-padding">
        <div class="d-flex flex-column h-100">
          <div class="flex-grow-1">
            <e-map mapname="map" />
          </div>
          <h3>Dataflow FPS</h3>
          <div class="h-30 theme-dark">
            <div class="chart-wrapper h-100" ref="chart-wrapper">
              <apexchart
                ref="chart"
                type="bar"
                height="100%"
                :options="chartOptions"
                :series="series"
              ></apexchart>
            </div>
          </div>
          <div class="text-block h-20 theme-light">
            <span id="text-block-content"> Project information </span>
          </div>
        </div>
      </div>

      <div class="col h-75">
        <quick-switcher @changed="subViewChanged($event)" ref="quickswitcher" />

        <control-bar
          @placeIcon="placeIcon($event)"
          :placing="isPlacing"
          hasmap
        />
      </div>
    </div>
  </div>
</template>

<script>
import ControlBar from "@/components/ControlBar";
import QuickSwitcher from "@/components/QuickSwitcher";
import VueApexCharts from "vue3-apexcharts";
import EMap from "@/components/Map";
import { onActivated } from "vue";

export default {
  name: "MapView",
  components: { QuickSwitcher, ControlBar, EMap, apexchart: VueApexCharts },
  data: function () {
    return {
      selectedMode: "map",
      tableData: this.$store.state.tableData,
      isPlacing: "",
      chartOptions: {},
      series: [],
      names: [],
      activeWorkers: [],
      currTelemetry: [],
    };
  },
  created() {
    onActivated(() => {
      window.dispatchEvent(new Event("resize"));
    });
  },
  mounted() {
    (this.chartOptions = {
      chart: {
        id: "vuechart-example",
        sparkline: {
          enabled: false,
        },
        foreColor: "#FFFFFF",
        redrawOnParentResize: true,
        animations: {
          enabled: false,
        },
      },
      tooltip: {
        enabled: false,
      },
      plotOptions: {
        bar: {
          columnWidth: "40%",
        },
      },
      colors: ["#d2a52b"],
      xaxis: {
        categories: this.names,
      },
    }),
      (this.series = [
        {
          name: "series-1",
          data: [],
        },
      ]);

    this.updateTelemetryGraph(this.$store.state.workerTelemetry);
  },
  watch: {
    "$store.state.workers": {
      handler: function (nv) {
        if (nv != null) {
          if (nv.length == 0) {
            this.names.length = 0;
            this.series = [{ data: [] }];
          } else {
            this.activeWorkers = nv.map((w) => w.display_name);
            this.updateTelemetryGraph(this.currTelemetry);
          }
        }
      },
      deep: true,
    },
    "$store.state.workerTelemetry": {
      handler: function (nv) {
        if (nv != null) {
          console.log("Telemetry was updated, thus FPS update!");
          this.currTelemetry = nv;
          this.updateTelemetryGraph(nv);
        }
      },
      deep: true,
    },
  },
  methods: {
    subViewChanged: function () {
      this.isDrawing = false;
      this.$refs.quickswitcher.stopMapInteraction();
      this.$refs.quickswitcher.setMapTooltip("");
      this.isPlacing = "";
    },
    placeIcon: function (type) {
      // Force view
      if (this.$refs.quickswitcher.getView() != "map") {
        this.$refs.quickswitcher.forceView("map");
        return;
      }

      // Clean capture area
      this.isDrawing = false;
      this.$refs.quickswitcher.stopMapInteraction();

      if (this.isPlacing !== type) {
        this.isPlacing = type;
        this.$refs.quickswitcher.setMapTooltip(
          `Click on the map to place a '${type.replace(/_/g, " ")}' icon`
        );
        this.$refs.quickswitcher.startMapInteraction((feature) => {
          // We need to add the icon to the GEOJSON for rendering purposes (in 3rd party GIS applications)
          if (feature.features[0].properties == null) {
            feature.features[0].properties = { icon: type };
          } else {
            feature.features[0].properties.icon = type;
          }

          const name = "Remarks";
          this.$store.commit("setImportedShapes", {
            name: name,
            geojson: feature,
          });
          this.$store.commit("setTableData", {
            name: name,
            properties: [name, 1, "Red", "4", "Delete"],
          });
          this.$refs.quickswitcher.setMapTooltip("");
          this.isPlacing = "";
        }, "Point");
      } else {
        this.$refs.quickswitcher.setMapTooltip("");
        this.isPlacing = "";
        this.$refs.quickswitcher.stopMapInteraction();
      }
    },
    updateTelemetryGraph(data) {
      this.names.length = 0;

      if (data) {
        let fpsData = [];
        let ctr = 0;
        data.forEach((value) => {
          if (this.activeWorkers.includes(value.display_name)) {
            let fpsObj = value.telemetry.find((t) => t.name == "input fps");
            if (fpsObj) {
              if (fpsObj.value && !isNaN(fpsObj.value)) {
                this.names[ctr] = value.display_name;
                fpsData.push(Math.round(fpsObj.value * 10) / 10);
                ctr++;
              }
            }
          }
        });
        this.series = [{ data: fpsData }];
      } else {
        this.series = [{ data: [] }];
      }
    },
  },
};
</script>

<style scoped lang="scss">
.text-block {
  background-color: white;
  color: var(--font-color);
  padding: 15px;
  font-family: "PT Sans", sans-serif;
}
</style>