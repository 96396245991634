import MapChildView from "@/views/map/MapChildView";
import CamsChildView from "@/views/map/CamsChildView";
import MapView from "@/views/map/MapView";
import LidarChildView from "@/views/map/LidarChildView";
import GPRChildView from "@/views/map/GPRChildView";
import CamsView from "@/views/cams/CamsView";
import ProjectInfoView from "@/views/project_info/ProjectInfoView";
import DataflowView from "@/views/dataflow/DataflowView";
import BrowserView from "@/views/browser/BrowserView";

export default [
    {
        path: '/',
        name: 'Home',
        component: MapView,
        children: [
            {
                name: 'Map',
                path: 'map',
                component: MapChildView
            },
            {
                name: 'CamsChildview',
                path: 'camschildview',
                component: CamsChildView
            },
            {
                name: 'Lidar',
                path: 'lidar',
                component: LidarChildView
            },
            {
                name: 'GPR',
                path: 'gpr',
                component: GPRChildView
            }
        ]
    },
    {
        name: 'Cams',
        path: '/cams',
        component: CamsView,
    },
    {
        name: 'ProjectInfo',
        path: '/projectinfo',
        component: ProjectInfoView,
    },
    {
        name: 'Dataflow',
        path: '/dataflow',
        component: DataflowView,
    },
    {
        name: 'Browser',
        path: '/browser',
        component: BrowserView,
    }
]

