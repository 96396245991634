<template>
  <div class="row h-100">
    <div class="col-4 h-100 no-padding">
      <div class="d-flex flex-column h-100">
        <h1 v-if="workers[selectedWorker]">
          Settings for: {{ workers[selectedWorker].display_name }}
        </h1>
        <h1 v-else>Worker Settings</h1>
        <div class="h-50">
          <h4 v-if="workers.length == 0">
            No workers are currently connected!
          </h4>
          <div
            class="h-100 p-5 align-content-stretch text-fit justify-content-center text-center"
            style="padding-top: 0 !important"
          >
            <e-settingvisualisation
              @settingupdated="updateSetting($event)"
              :data="workerOptions"
            />
          </div>
        </div>
        <div class="h-30">
          <div
            class="d-flex flex-row flex-wrap h-100 justify-content-center allow-overflow-x"
          >
            <e-button
              @click="workerClicked(idx)"
              v-for="(worker, idx) in workers"
              :key="worker"
              :text="worker.display_name"
              :active="idx == selectedWorker"
            />
          </div>
        </div>
        <div class="text-block h-20 theme-light">
          <span id="text-block-content"> Project information </span>
        </div>
      </div>
    </div>

    <div class="col h-75 no-padding">
      <div class="d-flex flex-column h-100">
        <div
          class="d-flex flex-row flex-wrap h-100"
          style="background-color: #242829"
        >
          <!-- Main -->
          <div
            id="preview-main"
            class="flex-grow-1 h-100"
            style="display: block"
            v-bind:style="{
              background:
                'url(' + mainStream.preview + ') center center no-repeat',
            }"
          ></div>
          <p v-if="streams.length == 0" class="no-preview-text">
            No video previews available..
          </p>
        </div>
        <!-- Sub -->
        <div
          class="d-flex flex-row flex-wrap h-50"
          style="background-color: #212627"
        >
          <div class="container-fluid no-margin">
            <div class="row h-100">
              <div
                class="preview-sub"
                v-bind:class="{
                  'col-4': streams.length <= 3,
                  'col-3': streams.length >= 4,
                  'width-50': streams.length == 1,
                }"
                v-for="(stream, index) in streams"
                v-bind:key="stream"
                v-bind:style="{
                  background: 'url(' + stream + ') center center no-repeat',
                }"
                @click="workerClicked(index)"
              >
                <div class="camera-name">
                  <div class="text">{{ workers[index].display_name }}</div>
                </div>
                <div v-if="selectedWorker == index" class="camera-streaming">
                  In Fullscreen
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <control-bar />
    </div>
  </div>
</template>

<script>
import ControlBar from "@/components/ControlBar";
import EButton from "@/components/Button";
import ESettingvisualisation from "@/components/WorkerControls/SettingVisualisation";
import Utility from "@/assets/js/utility";

export default {
  name: "EWorkersettings",
  data() {
    return {
      aspectRatio: 16 / 9, // 16:9 ratio
      workers: [],
      workerIds: [],
      mainStream: {
        workerId: "",
        preview: "", //"https://via.placeholder.com/1180x649",
      },
      dataStreams: new Map(), // key = workerid & value = img src
      selectedWorker: 0,
      objectURL: window.URL || window.webkitURL,
      deltaTime: new Map(),
      maxSubFPS: 1000, // 1
    };
  },
  components: { EButton, ControlBar, ESettingvisualisation },
  sockets: {
    hub_push_gui_preview: function (data) {
      console.log("New img stream");

      this.workers.forEach((worker) => {
        if (worker.id == data.id) {
          if (worker.subtype && worker.subtype.toUpperCase() == "LIDAR") return;

          const delta =
            performance.now() - this.deltaTime.get(worker.id) ||
            this.maxSubFPS + 1;

          const isMain = this.mainStream.workerId == data.id;
          let renderSub = false;
          if (!isMain && delta > this.maxSubFPS) {
            renderSub = true;
            this.deltaTime.set(worker.id, performance.now());
          } else if (!isMain) {
            return;
          }

          let arrayBuffer = data.buffer;
          let bytes = new Uint8Array(arrayBuffer);
          const decode = "data:image/jpg;base64," + Utility.encodeBytes(bytes);
          let img = new Image();
          img.src = decode;
          const self = this;
          img.onload = function () {
            if (isMain) {
              self.mainStream.preview = decode;
            }
            if (!isMain && renderSub) {
              self.dataStreams.set(worker.id, decode);
            }
          };
        }
      });
    },
  },
  watch: {
    "$store.state.workers": {
      handler: function (nv) {
        if (nv != null) {
          console.log("NEW WORKERS ARRIVED, ALL RISE!");
          console.log(nv);
          this.workers = nv;
          this.updatePreviews();
        }
      },
      immediate: true,
      deep: true,
    },
  },
  mounted() {
    const self = this;
    window.addEventListener("resize", function () {
      clearTimeout(window.resizedFinished);
      window.resizedFinished = setTimeout(function () {
        self.startPreview();
      }, 500);
    });

    window.dispatchEvent(new Event("resize"));
  },
  unmounted() {
    this.stopPreview();
  },
  computed: {
    workerOptions() {
      if (this.workers.length > 0) {
        const worker = this.workers[this.selectedWorker];
        if (worker) {
          return worker.settings;
        }
      }
      return [];
    },
    streams() {
      // Check if resolution has changed. If so, update it

      return Array.from(this.dataStreams.values());
    },
  },
  methods: {
    updateSetting: function (data) {
      data.id = this.workers[this.selectedWorker].id;
      console.log(data);
      this.$socket.emit("gui_push_setting", {
        id: data.id,
        settings: [
          {
            setting: data.setting,
            value: data.value,
          },
        ],
      });
    },
    workerClicked: function (previewIndex) {
      console.log(`Selected: ${previewIndex}`);
      this.selectedWorker = previewIndex;
      this.mainStream.workerId = this.workers[previewIndex].id;
      this.startPreview();
    },
    // eslint-disable-next-line no-unused-vars
    updatePreviews: function () {
      console.log("Call to updatepreview");
      if (this.workers.length > 0) {
        console.log("Call to updatepreview WITH WORKERS");

        // 1) Remove all worker streams that are no longer connected
        const workerIdMap = this.workers.map((worker) => worker.id);
        this.dataStreams.forEach((ds, key) => {
          if (!workerIdMap.includes(key)) {
            this.dataStreams.delete(key);
          }
        });

        // 2) Put all workers in the datastreams
        this.workers.forEach((worker, idx) => {
          this.dataStreams.delete(`placeholder-${idx}`);
          if (!this.dataStreams.has(worker.id)) {
            this.dataStreams.set(
              worker.id,
              "" //"https://via.placeholder.com/285x195"
            );
          }
        });

        // 3) Set the big image to the first worker
        if (!this.workers[this.selectedWorker]) {
          this.workerClicked(0);
        } else {
          this.mainStream.workerId = this.workers[this.selectedWorker].id;
        }

        // 4) Check if we need to start sending a preview command (new worker introduced)
        const workerMap = this.workers.map((worker) => worker.id);
        for (let i = 0; i < workerMap.length; i++) {
          if (!this.workerIds.includes(workerMap[i])) {
            this.workerIds = workerMap;
            const self = this;
            setTimeout(function () {
              self.startPreview();
            }, 100);
            return;
          }
        }
        for (let i = 0; i < this.workerIds.length; i++) {
          if (!workerMap.includes(this.workerIds[i])) {
            this.workerIds = workerMap;
            const self = this;
            setTimeout(function () {
              self.startPreview();
            }, 100);
            return;
          }
        }
      } else {
        this.dataStreams.clear();
        this.workerIds = [];
      }
    },
    startPreview: function () {
      if (document.readyState === "complete") {
        // Determine the resolution
        let mainSize;
        if (document?.getElementById("preview-main")?.getBoundingClientRect()) {
          const size = document
            .getElementById("preview-main")
            .getBoundingClientRect();
          const width = Math.round(size.height * this.aspectRatio);
          const height = Math.round(size.height);
          console.log(`Width: ${width} - Height: ${height} (16:9)`);
          //this.mainStream.preview = `https://via.placeholder.com/${width}x${height}`;
          mainSize = { width: width + "", height: height + "" };
        }

        let subSize;
        if (
          document
            ?.getElementsByClassName("preview-sub")[0]
            ?.getBoundingClientRect()
        ) {
          const size = document
            .getElementsByClassName("preview-sub")[0]
            .getBoundingClientRect();
          const width = Math.round(size.height * this.aspectRatio);
          const height = Math.round(size.height);
          console.log(`SUB Width: ${width} - Height: ${height} (16:9)`);
          subSize = { width: width + "", height: height + "" };
        }

        this.workers.forEach((worker) => {
          // Emit the actual command to the worker
          const res =
            this.mainStream.workerId == worker.id ? mainSize : subSize;

          // this.dataStreams.set(worker.id, "");

          if (res) {
            this.$socket.emit("gui_start_preview", {
              id: worker.id,
              fps: "15",
              resolution: res,
            });
          }
        });
      }
    },
    stopPreview: function () {
      this.workers.forEach((worker) => {
        this.$socket.emit("gui_stop_preview", { id: worker.id });
      });
    },
    convertDataURIToBlob: function (dataURI) {
      // Validate input data
      if (!dataURI) return;

      // Convert image (in base64) to binary data
      var base64Index = dataURI.indexOf(";base64,") + ";base64,".length;
      var base64 = dataURI.substring(base64Index);
      var raw = window.atob(base64);
      var rawLength = raw.length;
      var array = new Uint8Array(new ArrayBuffer(rawLength));

      for (let i = 0; i < rawLength; i++) {
        array[i] = raw.charCodeAt(i);
      }

      // Create and return a new blob object using binary data
      return new Blob([array], { type: "image/jpeg" });
    },
  },
};
</script>

<style scoped>
.no-preview-text {
  position: absolute;
  width: 100%;
  bottom: 60%;
  text-align: center;
  font-size: 125%;
}

.camera-name {
  position: absolute;
  width: 100%;
  height: 30px;
  bottom: 0;
  text-align: center;
  background: rgba(0, 0, 0, 0.6);
  margin-left: -15px;
}

.camera-streaming {
  position: absolute;
  width: 100%;
  height: 50px;
  line-height: 50px;
  bottom: calc(50% - 25px);
  color: var(--color-accent);
  text-transform: uppercase;
  text-align: center;
  background: rgba(0, 0, 0, 0.6);
  margin-left: -15px;
}

.camera-name .text {
  line-height: 30px;
}

.width {
  width: 50px;
}

.width-50 {
  width: 50%;
}

.allow-overflow-x {
  overflow-x: auto;
}

.text-fit {
  word-break: break-word;
}
</style>