<template>
  <div class="h-100" ref="containerviewer">
    <canvas ref="viewer" style="width: 100%; height: 100%"></canvas>
  </div>
</template>

<script>
import * as THREE from "three";
// import Stats from "three/examples/jsm/libs/stats.module";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import chroma from "chroma-js";

export default {
  name: "PointCloudViewer",
  props: {
      points: Object,
  },
  data() {
    return {
      canvasContainer: null,
      canvas: null,
      renderRequested: false,
    };
  },
  mounted() {
    this.canvasContainer = this.$refs.containerviewer;
    this.canvas = this.$refs.viewer;
    this.initScene();
  },
  watch: {
      points: function(newPoints) {
          this.feedFrame(newPoints);
      }
  },
  methods: {
    feedFrame(frame) {
      // Remove previous frame points
      if (!this.pointGeometry) {
        this.pointGeometry = new THREE.BufferGeometry();
      }

      const positions = [];
      const colors = [];
      const color = new THREE.Color();

      const bytes = new Float32Array(frame);
      for (let i = 0; i < bytes.length; i += 4) {
        const x = bytes[i + 1];
        const y = bytes[i + 2];
        const z = bytes[i];
        positions.push(x, y, z);

        // Colors
        const scale = chroma.scale("Spectral");
        color.set(scale(bytes[i + 3] / 100).hex());
        colors.push(color.r, color.g, color.b);
      }

      this.pointGeometry.setAttribute(
        "position",
        new THREE.Float32BufferAttribute(positions, 3)
      );
      this.pointGeometry.setAttribute(
        "color",
        new THREE.Float32BufferAttribute(colors, 3)
      );
      this.pointGeometry.computeBoundingSphere();

      const material = new THREE.PointsMaterial({
        size: 2,
        sizeAttenuation: false,
        vertexColors: true,
      });

      if (!this.hasPointGeometry) {
        const pointCloud = new THREE.Points(this.pointGeometry, material);
        this.scene.add(pointCloud);
        this.hasPointGeometry = true;
      }

      this.requestRenderIfNotRequested();
    },
    initScene() {
      // this.stats = new Stats();
      // this.stats.dom.style.removeProperty("top");
      // this.stats.dom.style.removeProperty("left");
      // this.canvasContainer.appendChild(this.stats.dom);

      // Camera Setup
      this.camera = new THREE.PerspectiveCamera(
        27,
        this.canvas.getBoundingClientRect().width /
          this.canvas.getBoundingClientRect().height,
        5,
        3500
      );
      this.camera.position.set(500, 500, 500);
      this.camera.lookAt(0, 0, 0);

      // Setup the scene
      this.scene = new THREE.Scene();
      this.scene.background = new THREE.Color(0x32393a);

      // Lighting
      this.ambientLight = new THREE.AmbientLight(0x606060, 1);
      this.scene.add(this.ambientLight);

      // Renderer
      const context = this.canvas.getContext("webgl2", { antialias: true });
      this.renderer = new THREE.WebGLRenderer({ canvas: this.canvas, context });
      this.renderer.setPixelRatio(window.devicePixelRatio || 1);
      this.renderer.setSize(
        this.canvas.getBoundingClientRect().width,
        this.canvas.getBoundingClientRect().height
      );
      this.canvasContainer.appendChild(this.renderer.domElement);

      // Axis Helper
      const axesHelper = new THREE.AxesHelper(500);
      this.scene.add(axesHelper);

      // Camera controls
      this.controls = new OrbitControls(this.camera, this.renderer.domElement);
      this.controls.addEventListener('change', this.requestRenderIfNotRequested.bind(this));
      this.controls.enableDamping = true;

      // Event Listeners
      window.addEventListener("resize", this.requestRenderIfNotRequested.bind(this), false);

      this.render();
      console.log("LiDAR initialized!");
    },
    render: function () {
      this.renderRequested = false;
      this.controls.update();
      this.renderer.render(this.scene, this.camera);
    },
    requestRenderIfNotRequested() {
      if (!this.renderRequested) {
        this.renderRequested = true;
        requestAnimationFrame(this.render.bind(this));
      }
    },
    onWindowResize() {
      const rect = this.canvasContainer.getBoundingClientRect();
      this.canvas.width = rect.width;
      this.canvas.height = rect.height;
      this.camera.aspect = rect.width / rect.height;
      this.camera.updateProjectionMatrix();
      this.renderer.setSize(rect.width, rect.height);
      this.render();
    },
  },
};
</script>

<style scoped>
</style>